.article {
    background-color: white;
    border-radius: 5px;
    margin: 15px auto;
    width: 60%;
    padding: 5px;
    padding-bottom: 10px;

    .articletitle {
        color: black;
    }

    .articlecontent {
        width: 95%;
        margin: 0 auto;
    }

    img {
        width: 100%;
        // border-radius: 5px;
    }

    .imgcap{
        width: 100%;
    }

    .badge {
        margin: 2px;
    }

    .timestamp {
        color: darkgray;
        padding: 5px;
        text-align: end;
    }
}