.form-label {
  background-color: white !important;
}

.contactpage {
  background-color: rgba(70, 70, 70, 0.2);
  backdrop-filter: blur(10px);
  padding: 20px;
  border-radius: 5px;
  height: 70vh;
  width: 75%;
  margin: 0 auto;
}
