.projects {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
}

h1,
h3 {
  text-align: center;
}

h3 {
  margin-bottom: 50px;
}

.jumbotron {
  background-color: transparent !important;
  margin: 0 auto;
}

.carouselCap {
  height: 50rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
