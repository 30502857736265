body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: url(./assets/moonnight.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100%;
  background-color: #0e0c0f;
}

h1 {
  font-family: 'Source Code Pro', monospace;
  color: white;
}

h3 {
  font-family: 'Source Code Pro', monospace;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
