@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.homepage {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: auto;
  color: white;
  text-shadow: 2px 2px 2px grey;
  align-items: center;
  justify-content: center;

  #greeting {
    height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    #mainlogo {
      display: flex;
      width: 95vw;
      margin: auto;
      align-items: center;
      justify-content: center;

      #logo {
        width: 250px;
        height: auto;
        filter: drop-shadow(3px 3px 5px grey);
        padding: 15px;
        border-right-style: solid;
        border-right-width: 3px;
        border-right-color: white;
      }

      .title {
        margin-left: 10px;
        font-family: 'Source Code Pro';

        h1 {
          font-size: 72px;
          text-align: center;
        }

        h3 {
          font-size: 30px;
          text-align: center;
          line-height: 1.2em;
        }
      }
    }

    #more {
      justify-content: center;
      font-size: 72px;

      &:hover {
        animation: shake 0.5s;
        cursor: pointer;
        color: darkgray;
      }
    }
  }

  #pages {
    display: grid;
    width: 95%;
    padding-bottom: 10vh;
    padding-top: 35vh;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-column-gap: 20px;
    align-items: center;
    justify-items: center;
    grid-auto-flow: row;

    .page {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      font-family: 'Source Code Pro';
      text-decoration: none;
      filter: blur(1px);
      transition: 0.5s;

      &:hover {
        filter: drop-shadow(2px 2px 2px grey);
        transform: scale(1.1);
        transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
        filter: blur(0px);
      }

      .linkLogo {
        height: 200px;
        width: auto;
        transition: 0.2s;
      }
    }
  }

  #icons {
    padding-bottom: 10vh;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-size: 4rem;

    .icon {
      padding: 15px;
      color: white;
      text-decoration: none;
      transition: 0.5s;
      filter: drop-shadow(1px 1px 2px grey);

      &:hover {
        color: whitesmoke;
        transform: scale(1.5);
        transition: transform 6s cubic-bezier(0.25, 0.45, 0.45, 0.95);
        filter: drop-shadow(0);
      }
    }
  }
}

@media all and (max-width: 750px) {
  #logo {
    display: none;
  }

  .title {
    margin-left: 0;

    h1 {
      font-size: 10px;
    }
  }

  .homepage {
    #pages {
      .page {
        filter: blur(0);
      }
    }
  }
}

// @media all and (min-width: 2000px) {
//     .homepage {
//         width: 1500px;
//     }
// }
